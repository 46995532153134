<template>
  <div class="container">
        <sign-up-modal v-if="hasNewSignupFlow"/>
      <div class="container">
        <h1>Edit profile</h1>
        <div class="row">
          <div class="col-md-4">
            <h5>User details</h5>
            <div class="text-muted">
              Your user details. Username, password, email and profile image.
            </div>
          </div>
          <div class="col-md-8 border-left">  
            <div class="form-row">
              <div class="form-group col-12">
                <label for="">Profile picture</label>
                <div class="border border-secondary text-center" style="width: 60px; height: 60px; line-height: 50px; margin-bottom: 10px; border-radius: 5px;">img</div>
                <button class="btn btn-secondary btn-sm">Change picture</button>
              </div>
              <div class="form-group col-md-6">
                <label for="">Username</label>
                <div>{{ user.username }} <span class="btn btn-link btn-sm">Change</span></div>
              </div>
              <div class="form-group col-md-6">
                <label for="">Phone number</label>
                <div>{{ user.phone }} <span class="btn btn-link btn-sm">Change</span></div>
              </div>
              <div class="form-group col-md-6">
                <label for="">Email</label>
                <div>{{ user.email }} <span class="btn btn-link btn-sm">Change</span></div>
              </div>
                <div class="form-group col-md-6">
                  <label>Your role</label>
                  <div>
                    {{ userDetails.role }} <router-link to="/user/role" class="btn btn-link btn-sm">Change</router-link>
                  </div>
                  <select class="form-control" v-model="userDetails.role">
                    <option v-for="(role, index) in userRoleOptions" :key="index" :value="role.value" :selected="role == userDetails.role">{{role.name}}</option>
                  </select>
                </div>
            </div>
          </div>
        </div>
        
        <div class="mt-4">
          <div class="row">
            <div class="col-md-4">
              <h5>Personal info</h5>
              <div class="text-muted">
                Your personal info. 
              </div>
            </div>
            <div class="col-md-8 border-left">  
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="">Country</label>
                  <select class="form-control" v-model="userDetails.country">
                    <option value="">Choose country</option>
                    <option value="">Denmark</option>
                  </select>
                </div>

                <div class="form-group col-md-6">
                  <label for="">Region</label>
                  <select class="form-control" v-model="userDetails.region">
                    <option value="">Choose region</option>
                    <option value="">Region hovedstaden</option>
                  </select>
                </div>


                <div class="form-group">
                  <button class="btn btn-primary btn-sm">Save changes</button>
                </div>
              </div>
            </div>
          </div>
        </div>




        <div v-if="userDetails.role == 'Professional'">
          <h4>Professional info</h4>
          <div class="form-group">

            <div class="form-group">
              <label for="">Choose your company</label>
              <input type="text" class="form-control" placeholder="Level" v-model="userDetails.worktitle">
              <div class="text-muted">Cant find your company?</div>
            </div>
            <div class="form-group">
              <label for="">Add company</label>
              <input type="text" class="form-control" placeholder="Level" v-model="userDetails.worktitle">
            </div>

          </div>
        </div>
        
        <div v-if="userDetails.role == 'Student'">
          <h4>Student info</h4>
          <div class="form-group">

            <div class="form-group">
              <label for="">Select education</label>
              <select type="text" class="form-control" placeholder="Level" v-model="userDetails.education">
                <option selected>Choose university</option>
                <option>Uni1</option>
              </select>
              <div class="text-muted">Cant find your university?</div>
            </div>
            <div class="form-group">
              <label for="">Select degree</label>
              <select type="text" class="form-control" placeholder="Level" v-model="userDetails.degree">
                <option selected>Choose degree</option>
                <option>Msc</option>
              </select>
              <div class="text-muted">Cant find your degree?</div>
            </div>
            <div class="form-group">
              <label for="">Select area</label>
              <select type="text" class="form-control" placeholder="Level" v-model="userDetails.studentArea">
                <option selected>Choose area</option>
                <option>IT mangement</option>
              </select>
              <div class="text-muted">Cant find your area?</div>
            </div>
          </div>
        </div>

        <div>{{ userDetails }}</div>
        <hr>
        <button @click="updateUserInfo(userDetails)" class="btn btn-primary">Save changes</button>
      </div>
      

    </div>
</template>

<script>
import {mapState} from 'vuex'
import firebase from 'firebase'
import _ from 'lodash'
import signUpModal from '../../components/signUpModal.vue'

export default {
  components: { signUpModal },
    name: 'user-profile',
    data: function() {
      return {
        // userinfo: _.cloneDeep(this.$store.state.user),
        testUserDisplayName: '',
        userRoleOptions: [
          {
            name: "Professional",
            value: "Professional"
          },
          {
            name: "Student",
            value: "Student"
          },
          {
            name: "Student counsellor",
            value: "Student counsellor"
          }
        ],
      }
    },
    created() {

    },
    computed: {
      ...mapState(['user']),
      hasNewSignupFlow(){
      const vm = this
      const isValid = vm.$store.getters.currentProgress >= 0 ? true : false
      const isStudent = vm.$store.getters.role === 'Student'
        if (!isValid && isStudent) {
          localStorage.setItem('notSignedUp', 1);
        }
        if (isValid) {
          localStorage.removeItem('notSignedUp');
        }
      return true
    },
      userDetails: function() {
        const userDetails =  _.cloneDeep(this.$store.state.user);
        return userDetails;
      }

    },
    methods: {
      updateUserInfo(payload) {
        // console.log(payload);
        // this.$store.dispatch('updateUserDetails', payload);
      },
  
      updateFirebaseUserDetails(payload) {
        this.$store.dispatch('updateFirebaseUserDetails', payload);
      }
    }
}
</script>

<style>

</style>